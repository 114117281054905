import React, { useState, useEffect, isValidElement } from "react";
import { Button, Message, Form, Input, Select } from "semantic-ui-react";
import api from "../../store/api";
import { useUser } from "../session/hooks";

import { Redirect, useParams } from "react-router-dom";

import "./prospectForm.css";
import TrInput from "../trInput/trInput";

const ProspectForm = () => {
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState(false);

  const [errors, setErrors] = useState([]);
  const [value, setValue] = useState();

  const [isValid, setIsValid] = useState();
  const [validationMessage, setValidationMessage] = useState();

  const [profiles, setProfiles] = useState([]);
  const [techs, setTechs] = useState([]);

  useEffect(async () => {
    const p = await getData('profiles');
    const t = await getData('techs');
    
    setProfiles(p);
    setTechs(t);



  }, [])

  const getData = async (what) => {
    const stored = window.localStorage.getItem(what);

    if (stored) {
      return JSON.parse(stored);
    }

    const { data } = await api.get(`v2/recruiting/${what}`);

    const options = data.map(({ _id, text }) => ({
      key: _id,
      value: _id,
      text
    }));

    window.localStorage.setItem(what, JSON.stringify(options));
    return options;
  }

  const onChange = (e,t) => {
    const name = e.target.name || t.name;
    const v = e.target.value || t.value;

    const newState = { ...value };
    newState[name] = v;

    // if (e.target.name === "companyDomain") setIsDomainValid(null);
    setValue(newState);
  };

  const validateLinkedin = async () => {
    if (!value || !value.linkedin || value.linkedin === "") {
      return;
    }

    const qs = {
      linkedin: value.linkedin
    };

    const { data } = await api.get("/v2/recruiting/validate", { params: qs });

    if (data.result === 1) {
      setIsValid(false);
      setValidationMessage('Opps... we already got that one.');
      return;

    }

    setIsValid(true);
    setValidationMessage('Yay! that\'s new!');
  };

  const sendProspect = async (e) => {
    debugger;
    e.preventDefault();
    // debugger;
    const required = [
      'firstName',
      'lastName',
      'linkedin',
      'profileId',
      'techIds',
      'lastJob',
      'lastCompany',
      'about',
      'firstYearInIT'
    ];

    const missing = required.filter((f) => !value[f] || value[f] === "");

    if (missing.length > 0) {
      setErrors(missing);
      return;
    }

    try {
      setLoading(true);
      await api.post("v2/recruiting/prospects", value);

      setSuccess(true);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <div>
        <h2>Error!...</h2>
        <p>{JSON.stringify(error.response.data)}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <section style={{ textAlign: "center" }}>
        <div>
          <img src="/img/blocks.gif" alt="southteams" style={{ width: 80 }} />
        </div>
      </section>
    );
  }

  if (success) {
    return (
      <section
        style={{ textAlign: "center" }}>
        <div>
          <h1>Prospect sent!</h1>
          <p>
            Click&nbsp;
            <Button
              className="ui button green"
              onClick={() => window.location.reload()}
            >
              Here
            </Button>{" "}
            to send a new one!
          </p>
        </div>
      </section>
    );
  }

  return (
    <React.Fragment>
      <h2>Let's add a new developer!</h2>

      <Form onSubmit={sendProspect} autoComplete="off">
        <h3>Developer Information</h3>
        <table style={{ width: "100%" }}>

          <TrInput
            name="linkedin"
            placeholder="Linkedin Url, for example: https://linkedin.com/in/sergio_denis..."
            label="LinkedIn *"
            onChange={onChange}
            errors={errors}
            type="url"
            autoFocus
            onBlur={validateLinkedin} 
            />

        </table>

        {
        validationMessage && <div style={{ padding: 5, color: isValid ? "green" : "red"  }}>
          { validationMessage }
        </div>
        }
        
        <table style={{ width: "100%", marginTop: 10 }}>
          <TrInput
            name="firstName"
            placeholder="First Name..."
            label="First Name *"
            onChange={onChange}
            errors={errors}
          />

          <TrInput
            name="lastName"
            placeholder="Last Name..."
            label="Last Name *"
            onChange={onChange}
            errors={errors}
            autoComplete={false}
          />

          <TrInput
            name="email"
            placeholder="Email Address..."
            label="Email"
            onChange={onChange}
            errors={errors}
          />

          <tr>
            <th>Location *</th>
            <td>
              <Select onChange={onChange} search name="location" placeholder="Location..." defaultValue='CABA o GBA' options={[
                { key: 'CABA o GBA', value:'CABA o GBA', text:'CABA o GBA'  },
                { key: 'Argentina (Interior)', value:'Argentina (Interior)', text:'Argentina (Interior)'  },
                { key: 'Uruguay', value:'Uruguay', text:'Uruguay'  },
                { key: 'LATAM', value:'LATAM', text:'LATAM'  },
                { key: 'Other', value:'Other', text:'Other'  },
              ]}  />
            </td>
          </tr>


          </table>

          <h3>Profile Information</h3>

          <table style={{ width: "100%" }}>

          <tr>
            <th>Profile *</th>
            <td>  
              <Select onChange={onChange}  
                      search 
                      name="profileId" 
                      placeholder="Profile..." 
                      options={profiles}
                      error={(errors || []).indexOf('profileId') !== -1 && "This field is required."}  />
            </td>
          </tr>
          
          <tr>
            <th>Techs *</th>
            <td>
              <Select onChange={onChange} 
                      multiple  
                      search 
                      name="techIds" 
                      placeholder="Techs..." 
                      options={techs} 
                      error={(errors || []).indexOf('techIds') !== -1 && "This field is required."}  />
            </td>
          </tr>


          <TrInput
            name="lastCompany"
            placeholder="Last Company..."
            label="Last Company *"
            onChange={onChange}
            errors={errors}
            
          />

          <TrInput
            name="lastJob"
            placeholder="Last Position..."
            label="Last Position *"
            onChange={onChange}
            errors={errors}
            
          />

          <TrInput
            name="about"
            placeholder="About..."
            label="About *"
            onChange={onChange}
            errors={errors}
            
          />

          <TrInput
            name="firstYearInIT"
            placeholder="First year in IT..."
            label="First year in IT *"
            onChange={onChange}
            errors={errors}
            type="number"
            
          />       

          <tr>
            <th style={{ verticalAlign: 'top'}}>More info</th>
            <td>
              <textarea
                name="info"
                onChange={onChange}
                errors={errors}
                placeholder="Add some relevant notes."
                style={{ height: '40px!important', resize: `none` }}
                autoComplete={false}
              >
              </textarea>
            </td>
          </tr>
        </table>

        {/* <div>
          {JSON.stringify(value)}
          </div> */}

        <div style={{ textAlign: "center", margin: 50 }}>
          <button
            onClick={sendProspect}
            className="ui button primary"
            type="submit"
            style={{ fontSize: 20 }}
            disabled={!isValid}>
            Send Prospect!
          </button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default ProspectForm;
