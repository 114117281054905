import React, { useState } from "react";
import Login from "./views/login";
import api from "../../store/api";

const SessionContext = React.createContext();

const SessionContextProvider = ({ children }) => {
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState(true);

  const token = window.localStorage.getItem("token");
  const user = JSON.parse(window.localStorage.getItem("user"));

  const logOut = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
    window.location = "/";
  };

  const handleGoogleResponse = (r) => {
    if (r.error) {
      return;
    }

    setLoaded(false);

    const { accessToken } = r;
    api
      .post("v1/auth/login", { access_token: accessToken })
      .then((res) => {

  
        window.localStorage.setItem("token", res.data.token);
        window.localStorage.setItem("user", JSON.stringify(res.data.user));
        setLoaded(true);
        window.location = "/";
      })
      .catch((e) => {

        console.log(e);
        
        setLoaded(true);
        setError({
          message: "Sorry dude. Looks like you are out of this party.",
        });
      });
  };

  const state = { user, error, loaded };
  const actions = { logOut, handleGoogleResponse };

  if (!token) {
    return (
      <SessionContext.Provider value={{ state, actions }}>
        <Login error={error} />
      </SessionContext.Provider>
    );
  }

  return (
    <SessionContext.Provider value={{ state, actions }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext as default, SessionContextProvider as Provider };
