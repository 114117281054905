import React from "react";
import { Input } from "semantic-ui-react";

const trInput = ({
  name,
  label,
  placeholder,
  errors,
  value,
  onChange,
  onBlur,
  autoFocus,
  type,
}) => (
  <tr>
    <th>{label}</th>
    <td>
      <Input
        name={name}
        placeholder={placeholder}
        error={(errors || []).indexOf(name) !== -1 && "This field is required."}
        value={(value || {})[name]}
        onChange={onChange}
        autoFocus={autoFocus}
        onBlur={onBlur}
        type={type}
      />
    </td>
  </tr>
);

export default trInput;
