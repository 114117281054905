import React from "react";
import GoogleLogin from "react-google-login";
import "./login.css";
import { Button } from "semantic-ui-react";
import { useSessionActions, useSessionState } from "../hooks";

const Login = (props) => {
  const { handleGoogleResponse } = useSessionActions();
  const { error, loaded } = useSessionState();

  if (!loaded) {
    return (
      <section className="login">
        <div>
          <img src="/img/blocks.gif" alt="southteams" style={{ width: 80 }} />
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="login">
        <div>
          <img src="/img/isologo.png" alt="southteams" />
        </div>
        <p className="error">{error.message}</p>
      </section>
    );
  }

  return (
    <section className="login">
      <div>
        <img src="/img/isologo.png" alt="southteams" />
      </div>
      <div>
        <GoogleLogin
          clientId="982566890486-9cgi15nl7t3bpbt8v2rqmldtko6ht5mk.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={handleGoogleResponse}
          onFailure={handleGoogleResponse}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              color="red"
            >
              Login with Google
            </Button>
          )}
        />
      </div>
    </section>
  );
};

export default Login;
