import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from "react-router-dom";

import './index.css';
import 'semantic-ui-css/semantic.min.css'

import App from './App.js';

import { Provider as SessionProvider } from "./components/session/sessionContext";

const Index = () => (
  <SessionProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SessionProvider>
);
  
ReactDOM.render(<Index />, document.getElementById('root'));
  