import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import NavMenu from "./components/navMenu/navMenu";

import ProspectForm from "./components/prospectForm/prospectForm";

const App = () => (
  <React.Fragment>
    <NavMenu />
    <div style={{ padding: 25 }}>
      <Switch>
        <Route path="/" exact component={ProspectForm} />

        {/* <Route exact path="/candidate/:candidateId" component={Candidate} /> */}
      </Switch>
    </div>
  </React.Fragment>
);

export default App;
